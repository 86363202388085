import React from "react";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Grid from "@material-ui/core/Grid";
import Grow from "@material-ui/core/Grow";
import Typography from "@material-ui/core/Typography";
import TimelineIcon from "@material-ui/icons/Timeline";
import Chart from "./Chart";

const CardGraphic = props => {
  let { listaAnos, parcelas, cotacao, colors } = props;
  return (
    <div style={{ marginTop: 5 }}>
      <Grid container spacing={1}>
        {listaAnos.length > 0 && (
          <Grid item xs={12}>
            <Grow in={true}>
              <Card style={{ paddingLeft: 10, paddingRight: 10 }}>
                <TimelineIcon
                  color="secondary"
                  style={{
                    fontSize: 30,
                    float: "left",
                    marginRight: "10px",
                    marginTop: "21px",
                    marginLeft: "24px",
                    color: "#f75a48"
                  }}
                />
                <Typography
                  style={{ marginTop: 20, marginBottom: 0 }}
                  variant="h6"
                  component="h2"
                >
                  Gráfico
                </Typography>
                <CardContent style={{ overflowX: "auto" }}>
                  <div style={{ minWidth: 850 }}>
                    <Chart parcelas={parcelas} colors={colors} />
                  </div>
                </CardContent>
                <CardContent style={{ paddingTop: 0 }}>
                  <p style={{ fontSize: 12 }}>
                    <em>
                      <b>*</b> Como estimativa, as parcelas futuras em USD estão
                      convertidas pela cotação PTAX = R$ {cotacao}
                    </em>
                  </p>
                </CardContent>
              </Card>
            </Grow>
          </Grid>
        )}
      </Grid>
    </div>
  );
};
export default CardGraphic;
