import React from "react";
import Grid from "@material-ui/core/Grid";
import Grow from "@material-ui/core/Grow";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import { GoogleLogout } from "react-google-login";

const NotAllowedUserView = props => {
  const { email } = props.data;
  const { responseGoogleLogout } = props.functions;

  return (
    <div className="mainChartDiv" style={{ marginTop: 5 }}>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Grow in={true}>
            <Card>
              <CardContent>
                <div className="topBody">
                  <p style={{ paddingBottom: 10 }}>
                    O e-mail <b>{email}</b> não está autorizado para este tipo
                    de consulta.
                  </p>
                  <p>
                    <b>*</b> Caso necessite de autorização, favor enviar sua
                    solicitação para o e-mail <b>contasareceber@safetec.com.br</b>
                  </p>
                  <p className="logout">
                    <b>*</b> Caso deseje fazer login com outra conta, clique
                    nesse botão:{"\t"}
                    <GoogleLogout
                      clientId="369967608635-ejfvu0mncqbpkj4nkecjt6lf73alinm8.apps.googleusercontent.com"
                      buttonText="Logar Novamente"
                      onLogoutSuccess={responseGoogleLogout}
                    />
                  </p>
                </div>
              </CardContent>
            </Card>
          </Grow>
        </Grid>
      </Grid>
    </div>
  );
};

export default NotAllowedUserView;
