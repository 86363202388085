import React, { useState, useEffect, useMemo, useRef } from "react";
import { Button } from "@material-ui/core";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import FormControl from "@material-ui/core/FormControl";
import Grid from "@material-ui/core/Grid";
import Grow from "@material-ui/core/Grow";
import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";
import Typography from "@material-ui/core/Typography";
import MaskedInput from "react-text-mask";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import "./styles.css";

const masks = {
  cnpj: [
    /\d/,
    /\d/,
    ".",
    /\d/,
    /\d/,
    /\d/,
    ".",
    /\d/,
    /\d/,
    /\d/,
    "/",
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    "-",
    /\d/,
    /\d/,
  ],
  cpf: [
    /\d/,
    /\d/,
    /\d/,
    ".",
    /\d/,
    /\d/,
    /\d/,
    ".",
    /\d/,
    /\d/,
    /\d/,
    "-",
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    /\d/,
  ],
};

const TextMaskCustomCnpj = (props) => {
  const { inputRef, ...other } = props;
  return (
    <MaskedInput
      {...other}
      ref={(ref) => {
        if (ref) {
          inputRef(ref.inputElement);
        } else {
          inputRef(null);
        }
      }}
      mask={masks.cnpj}
      guide={false}
    />
  );
};

const TextMaskCustomCpf = (props) => {
  const { inputRef, ...other } = props;
  return (
    <MaskedInput
      {...other}
      ref={(ref) => {
        if (ref) {
          inputRef(ref.inputElement);
        } else {
          inputRef(null);
        }
      }}
      mask={masks.cpf}
      guide={false}
    />
  );
};

const Search = (props) => {
  const { cnpj, disableButton, demo, email } = props.data;
  const {
    handleChangeInput,
    handleChangeCheckbox,
    handleClickButton,
  } = props.functions;

  const searchFieldRef = useRef();

  const regexCpfAndCnpj = useMemo(() => {
    const brokenCpf = /(^\d{3}.\d{3}.\d{3}-\d{3}$)/;
    const brokenCnpj = /(^\d{2}.\d{3}.\d{3}\/\d{3}$)/;
    return { brokenCpf, brokenCnpj };
  }, []);

  const [mask, setMask] = useState("cpf");

  useEffect(() => {
    let maskedCpfOrCnpj;
    if (mask === "cpf" && regexCpfAndCnpj.brokenCnpj.test(cnpj)) {
      maskedCpfOrCnpj = cnpj
        .replace(/[^\d]/g, "")
        .replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4");
    } else if (mask === "cnpj" && regexCpfAndCnpj.brokenCpf.test(cnpj)) {
      maskedCpfOrCnpj = cnpj
        .replace(/[^\d]/g, "")
        .replace(/(\d{2})(\d{3})(\d{3})(\d{4})/, "$1.$2.$3/$4");
    } else if (cnpj.replace(/[^\d]/g, "").length === 11) {
      maskedCpfOrCnpj = cnpj
        .replace(/[^\d]/g, "")
        .replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4");
    } else if (cnpj.replace(/[^\d]/g, "").length === 14) {
      maskedCpfOrCnpj = cnpj
        .replace(/[^\d]/g, "")
        .replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, "$1.$2.$3/$4-$5");
    }

    if (maskedCpfOrCnpj) {
      handleChangeInput(
        "cnpj",
        "value"
      )({
        target: {
          value: maskedCpfOrCnpj,
        },
      });
    }
    searchFieldRef.current && searchFieldRef.current.focus();
  }, [cnpj, handleChangeInput, mask, regexCpfAndCnpj]);

  const changeMask = (name, field) => (e) => {
    if (e.target.value.length > 14) {
      setMask("cnpj");
    } else {
      setMask("cpf");
    }
    handleChangeInput(name, field)(e);
  };

  return (
    <Grid item xs={12} sm={6}>
      <Grow in={true}>
        <Card>
          <CardContent style={{ paddingBottom: "17px" }}>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <Typography variant="h6" color={"initial"} component="h4">
                Consulta
              </Typography>
              {email.split("@")[1] === "safetec.com.br" && (
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={demo}
                      onChange={handleChangeCheckbox("demo", "checked")}
                      value={demo}
                      style={{
                        color: "#f75a48",
                        padding: "0px 5px",
                      }}
                    />
                  }
                  label="Demo"
                  labelPlacement="end"
                  style={{ margin: "9px 0px -1px 0px" }}
                />
              )}
            </div>
            <Grid container spacing={1}>
              <Grid item xs={8}>
                <FormControl style={{ width: "100%", marginTop: 5 }}>
                  <InputLabel htmlFor="cnpjInput">CPF ou CNPJ</InputLabel>
                  <Input
                    inputRef={searchFieldRef}
                    value={cnpj}
                    onChange={changeMask("cnpj", "value")}
                    id="cnpjInput"
                    style={{ marginBottom: "6px" }}
                    type={demo ? "password" : "text"}
                    inputComponent={
                      mask === "cnpj" ? TextMaskCustomCnpj : TextMaskCustomCpf
                    }
                  />
                </FormControl>
              </Grid>
              <Grid item xs={4}>
                <Button
                  size="small"
                  disabled={disableButton}
                  onClick={handleClickButton("search")}
                  className="buttonSearch"
                  style={{
                    float: "right",
                    marginTop: 22,
                    fontWeight: "bold",
                    background: disableButton ? "rgb(224,224,224)" : "#f75a48",
                    color: disableButton ? "rgb(170,170,170)" : "white",
                  }}
                >
                  Buscar
                </Button>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Grow>
    </Grid>
  );
};

export default Search;
