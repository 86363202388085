import React from "react";
import Grid from "@material-ui/core/Grid";
import Grow from "@material-ui/core/Grow";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import { GoogleLogin } from "react-google-login";
import './styles.css';

const Login = props => {
  const { responseGoogleLogin, isSignedIn } = props;

  return (
    <Grid item xs={12}>
      <Grow in={true}>
        <Card>
          <CardContent>
            <Grid item xs={12}>
              <div className="topBody">
                <h2>Login necessário!</h2>
                <p align="justify" style={{ marginBottom: "0px" }}>
                  Aguarde enquanto verificamos sua autenticação. É necessário
                  que seu navegador permita pop-ups. Caso demore, favor clicar
                  no botão abaixo.
                </p>
              </div>
            </Grid>
            <Grid item xs={12} style={{ display: "flex" }}>
              <GoogleLogin
                className="loginButton"
                clientId="369967608635-ejfvu0mncqbpkj4nkecjt6lf73alinm8.apps.googleusercontent.com"
                onSuccess={responseGoogleLogin}
                onFailure={responseGoogleLogin}
                cookiePolicy={"single_host_origin"}
                buttonText="Login com Google"
                isSignedIn={isSignedIn}
                autoLoad={!isSignedIn}
              />
            </Grid>
          </CardContent>
        </Card>
      </Grow>
    </Grid>
  );
};

export default Login;
