import React from "react";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import "./styles.css";

const CorporateName = props => {
  return (
    <Card className="corporate-name-container">
      <CardContent
        className="corporate-name"
        style={{
          padding: "6px 4px 4px 4px"
        }}
      >
        <h2>{props.name + (props.filiais ? " + Filiais" : "")}</h2>
      </CardContent>
    </Card>
  );
};

export default CorporateName;
