import React from "react";
import Grid from "@material-ui/core/Grid";
import CardGroupedValues from "./CardGroupedValues";

const TopValues = (props) => {
  let { colors, values } = props;
  const totalEmAtraso = values.boletosEmAtraso.reduce(
    (total, boletoAtual) => total + boletoAtual.valor,
    0
  );

  const totalAVencer = values.boletosAVencer.reduce(
    (total, boletoAtual) => total + boletoAtual.valor,
    0
  );
  return (
    <div style={{ marginTop: 5 }}>
      <Grid container spacing={1}>
        <CardGroupedValues
          xs={6}
          sm={3}
          title="Pago"
          fill={colors.colorPago}
          valueBrl={values.totalPago}
          boletos={values.boletosPagos}
          titleDialog="NFS-es Pagas"
          classNameStatusBoleto="cardLinkBoletoColorPago"
          demo={values.demo}
        />
        <CardGroupedValues
          xs={6}
          sm={3}
          title="Em Atraso"
          fill={colors.colorEmAtraso}
          valueBrl={totalEmAtraso}
          // parcelas={values.parcelas}
          // boletosEmAtraso={values.boletosEmAtraso}
          boletos={values.boletosEmAtraso}
          titleDialog="Boletos em Atraso"
          classNameStatusBoleto="cardLinkBoletoColorAtrasado"
          demo={values.demo}
        />

        <CardGroupedValues
          xs={6}
          sm={3}
          title="A Vencer"
          fill={colors.colorAVencer}
          valueBrl={totalAVencer}
          // parcelas={values.parcelas}
          // boletosAVencer={values.boletosAVencer}
          boletos={values.boletosAVencer}
          titleDialog="Boletos a Vencer"
          classNameStatusBoleto="cardLinkBoletoColorAVencer"
          demo={values.demo}
        />

        <CardGroupedValues
          xs={6}
          sm={3}
          title="Futuras"
          fill={colors.colorFuturas}
          valueBrl={values.totalFuturaBrl}
          valueUsd={values.totalFuturaUsd}
        />
      </Grid>
    </div>
  );
};

export default TopValues;
