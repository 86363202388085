import React from "react";
import Grid from "@material-ui/core/Grid";
import Grow from "@material-ui/core/Grow";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import { Button, MenuItem, Select } from "@material-ui/core";
import { ClipLoader } from "react-spinners";
import './styles.css';

const SearchFilters = props => {
  const {
    filiais,
    contratosAtivos,
    listaAnos,
    fullLoading,
    ano,
    disableSelect,
    disableButton,
    filters,
    chartLoading,
    authorized
  } = props.data;

  const {
    handleClickButton,
    handleChange
  } = props.functions;

  return (
    <Grid item xs={12} sm={6}>
      <Grow in={true}>
        <Card>
          <CardContent>
            <Grid container spacing={1}>
              <Grid item xs={12} sm={5}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={filiais}
                      onChange={handleChange("filiais", "checked")}
                      value={filiais}
                      style={{
                        color: "#f75a48"
                      }}
                    />
                  }
                  label="Incluir Filiais"
                  labelPlacement="end"
                />
              </Grid>
              <Grid item xs={12} sm={7}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={contratosAtivos}
                      onChange={handleChange("contratosAtivos", "checked")}
                      value={contratosAtivos}
                      style={{
                        color: "#f75a48"
                      }}
                    />
                  }
                  label="Apenas Contratos Ativos"
                  labelPlacement="end"
                />
              </Grid>
              <Grid item xs={4}>
                {/* <CalendarTodaySharpIcon
                          color="secondary"
                          style={{
                            fontSize: 20,
                            float: "left",
                            marginRight: "10px",
                            marginTop: "8px",
                            color:
                              listaAnos.length > 0 && !fullLoading
                                ? "#333"
                                : "rgb(170,170,170)"
                          }}
                        /> */}
                <Typography
                  variant="body1"
                  component="p"
                  style={{
                    marginTop: 10,
                    color:
                      listaAnos.length > 0 && !fullLoading
                        ? "#333"
                        : "rgb(170,170,170)"
                  }}
                >
                  Período:
                </Typography>
              </Grid>
              <Grid item xs={4} style={{ display: "flex" }}>
                <Select
                  value={ano}
                  onChange={handleChange("ano", "value")}
                  disabled={
                    disableSelect || !(listaAnos.length > 0 && !fullLoading)
                  }
                  inputProps={{
                    name: "ano"
                  }}
                  style={{ width: "80px", margin: "0 auto" }}
                >
                  {listaAnos.map(dt => (
                    <MenuItem label="Select a year" value={dt} key={dt}>
                      {dt}
                    </MenuItem>
                  ))}
                </Select>
              </Grid>
              <Grid item xs={4}>
                <div style={{ float: "right", marginRight: "20" }}>
                  <Button
                    size="small"
                    disabled={
                      disableButton ||
                      (filiais === filters.filiais &&
                        contratosAtivos === filters.contratosAtivos &&
                        ano === filters.ano)
                    }
                    onClick={handleClickButton("apply")}
                    className="buttonApply"
                    style={{
                      float: "right",
                      fontWeight: "bold",
                      background:
                        disableButton ||
                        (filiais === filters.filiais &&
                          contratosAtivos === filters.contratosAtivos &&
                          ano === filters.ano)
                          ? "rgb(224,224,224)"
                          : "#f75a48",
                      color: disableButton ? "rgb(170,170,170)" : "white",
                      display:
                        fullLoading ||
                        chartLoading ||
                        filters.ano === undefined ||
                        !authorized
                          ? 
                            "none"
                          : "inherit"
                    }}
                  >
                    Aplicar
                  </Button>
                  <ClipLoader
                    size={25}
                    color="#f75a48"
                    loading={chartLoading}
                    styles={{
                      display: disableButton || fullLoading ? "none" : "inherit"
                    }}
                  />
                </div>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Grow>
    </Grid>
  );
};

export default SearchFilters;
