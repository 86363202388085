import React from "react";
import BottomCardCnpjs from "./BottomCardCnpjs";
import Grid from "@material-ui/core/Grid";

const BottomValues = (props) => {
  const { colors, cnpjAtraso, cnpjAVencer, demo } = props;
  return (
    <div style={{ marginTop: 5 }}>
      <Grid container spacing={1}>
        <BottomCardCnpjs
          xs={12}
          sm={6}
          color={colors.colorEmAtraso}
          title={cnpjAtraso.length > 0 ? "Em Atraso" : "Sem Parcelas Em Atraso"}
          cnpjs={cnpjAtraso}
          demo={demo}
        />
        <BottomCardCnpjs
          xs={12}
          sm={6}
          color={colors.colorAVencer}
          title={cnpjAVencer.length > 0 ? "A Vencer" : "Sem Parcelas A Vencer"}
          cnpjs={cnpjAVencer}
          demo={demo}
        />
      </Grid>
    </div>
  );
};

export default BottomValues;
