import React from "react";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Grid from "@material-ui/core/Grid";
import Divider from "@material-ui/core/Divider";
import Grow from "@material-ui/core/Grow";
import Typography from "@material-ui/core/Typography";
import { numberWithCommas } from "../../utils";

const BottomCardCnpjs = (props) => {
  let { xs, sm, color, title, cnpjs, demo } = props;

  return (
    <Grid item xs={xs} sm={sm}>
      <Grow in={true}>
        <Card>
          <CardContent style={{ padding: "16px 6px 16px 16px" }}>
            <div>
              <div className="card-status" style={{ background: color }}></div>
            </div>
            <Typography variant="h6" component="h2">
              <svg height="10" width="15">
                <circle cx="4" cy="4" r="4" fill={color} />
              </svg>
              {title}
            </Typography>
            {cnpjs.map((elem) => (
              <div key={(!demo ? elem.cnpj : Math.random()) + " " + elem.value}>
                <Typography variant="body2" component="p">
                  <b>{demo ? "00.000.000/0000-00" : elem.cnpj}</b>
                  {": R$ " + numberWithCommas(elem.value)}
                </Typography>
                <Divider />
              </div>
            ))}
          </CardContent>
        </Card>
      </Grow>
    </Grid>
  );
};

export default BottomCardCnpjs;
