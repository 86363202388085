import React, { useCallback } from "react";
import {
  CartesianGrid,
  Legend,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import CustomizedDot from "../CustomizedDot";
import CustomizedLabel from "../CustomizedLabel";
import CustomTooltip from "../CustomTooltip";

const Chart = (props) => {
  const { parcelas, colors } = props;

  const renderBoldLegendText = useCallback(
    (value) => (
      <span style={{ fontWeight: "bold", fontSize: "0.9em" }}>{value}</span>
    ),
    []
  );

  return (
    <ResponsiveContainer minHeight="300px" width="100%">
      <LineChart
        width={900}
        height={0}
        data={parcelas}
        margin={{
          top: 20,
          right: 0,
          left: 0,
          bottom: 10,
        }}
      >
        <CartesianGrid horizontal={false} />
        <XAxis dataKey="name" height={60} padding={{ left: 30, right: 30 }} />
        <YAxis type="number" />
        <Tooltip content={<CustomTooltip parcelas={parcelas} />} />
        <Legend formatter={renderBoldLegendText} />
        <Line name="Pago" type="monotone" stroke={colors.colorPago} />
        <Line name="Em Atraso" type="monotone" stroke={colors.colorEmAtraso} />
        <Line name="A Vencer" type="monotone" stroke={colors.colorAVencer} />
        <Line name="Futuras" type="monotone" stroke={colors.colorFuturas} />
        <Line
          strokeWidth="3"
          stroke="#aeeff0"
          type="monotone"
          dataKey="value"
          legendType="none"
          dot={<CustomizedDot parcelas={parcelas} colors={colors} />}
          label={<CustomizedLabel />}
          activeDot={{ r: 1 }}
        />
      </LineChart>
    </ResponsiveContainer>
  );
};

export default Chart;
