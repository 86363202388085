import React, { useState } from "react";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Grid from "@material-ui/core/Grid";
import Grow from "@material-ui/core/Grow";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import moment from "moment";
import "moment/locale/pt-br";
import "./styles.css";
import { numberWithCommas } from "../../utils";

moment.locale("pt-br");

function CardGroupedValues(props) {
  let {
    xs,
    sm,
    title,
    fill,
    valueBrl,
    valueUsd,
    titleDialog,
    classNameStatusBoleto,
    boletos,
    demo,
  } = props;

  const [openDialog, setOpenDialog] = useState(false);

  const handleOpen = () => {
    setOpenDialog(true);
  };

  const handleClose = () => {
    setOpenDialog(false);
  };

  const descriptionElementRef = React.useRef(null);
  React.useEffect(() => {
    if (openDialog) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [openDialog]);

  const BoletosInfo = () => {
    return (
      boletos &&
      boletos.map((boleto) => (
        <DialogContentText
          id="scroll-dialog-description"
          ref={descriptionElementRef}
          tabIndex={-1}
          key={boleto.nf}
        >
          {moment(boleto.vencimento).format("MMM/YY").toUpperCase() + ": "}
          <a
            className={`linkBoleto ${
              !boleto.linkNf ? "disabledLinkBoleto" : ""
            }`}
            href={boleto.linkNf}
            target="_blank"
            rel="noopener noreferrer"
          >
            NF {demo ? "00000" : boleto.nf}
          </a>
          {title !== "Pago" &&
            ` - 
          R$ ${boleto.valor} - `}
          {title !== "Pago" && (
            <a
              className={`linkBoleto ${
                !boleto.linkBoleto ? "disabledLinkBoleto" : ""
              }`}
              href={boleto.linkBoleto}
              target="_blank"
              rel="noopener noreferrer"
            >
              Boleto
            </a>
          )}
        </DialogContentText>
      ))
    );
  };
  return (
    <Grid item xs={xs} sm={sm}>
      <Grow in={true}>
        <Card>
          <Dialog
            open={openDialog}
            onClose={handleClose}
            scroll="paper"
            maxWidth="xl"
            fullWidth={false}
            aria-labelledby="scroll-dialog-title"
            aria-describedby="scroll-dialog-description"
          >
            <DialogTitle id="scroll-dialog-title">{titleDialog}</DialogTitle>
            <DialogContent dividers={true}>
              {/* {dialogContentText(boletos)} */}
              <BoletosInfo />
              {/* {dialogContentText(boletos)} */}
            </DialogContent>
            <DialogActions>
              <Button
                onClick={handleClose}
                style={{
                  fontWeight: "bold",
                  background: "#f75a48",
                  color: "white",
                }}
              >
                Fechar
              </Button>
            </DialogActions>
          </Dialog>
          <CardContent
            onClick={(boletos && boletos.length > 0 && handleOpen) || null}
            style={{
              padding:
                (valueBrl > 0 && valueUsd > 0) || boletos
                  ? "16px 6px 10px 16px"
                  : "16px 6px 16px 16px",
              cursor: boletos && boletos.length > 0 ? "pointer" : "default",
            }}
          >
            <div>
              <div className="card-status" style={{ background: fill }}></div>
            </div>
            <Typography
              variant="h6"
              component="h2"
              style={{
                marginTop: -14,
              }}
            >
              <svg height="10" width="15">
                <circle cx="4" cy="4" r="4" fill={fill} />
              </svg>
              {title}
            </Typography>
            {valueUsd > 0 && (
              <Typography
                variant="body2"
                component="p"
                style={{
                  paddingBottom:
                    (!valueBrl || valueBrl === 0) && title === "Futuras"
                      ? 14
                      : 0,
                }}
              >
                {"U$ " + numberWithCommas(valueUsd)}
              </Typography>
            )}
            {(!valueUsd || valueUsd === 0 || valueBrl > 0) && (
              <Typography
                variant="body2"
                component="p"
                style={{
                  paddingBottom:
                    (!valueUsd || valueUsd === 0) && title === "Futuras"
                      ? 14
                      : 0,
                }}
              >
                {"R$ " + numberWithCommas(valueBrl)}
              </Typography>
            )}
            {boletos && (
              <Typography
                className={`cardLinkBoletos ${
                  boletos && boletos.length > 0 ? classNameStatusBoleto : ""
                }`}
                variant="body2"
                component="p"
              >
                {title === "Pago" ? "NFS-ES" : "BOLETOS"}
              </Typography>
            )}
          </CardContent>
        </Card>
      </Grow>
    </Grid>
  );
}

export default CardGroupedValues;
