import React from "react";
import TopValues from "./TopValues";
import CardGraphic from "./CardGraphic";
import BottomValues from "./BottomValues";
import CorporateName from "./CorporateName";

const AllowedUserView = props => {
  const {
    colors,
    listaAnos,
    parcelas,
    cotacao,
    totalPago,
    totalEmAtraso,
    totalAVencer,
    totalFuturaBrl,
    totalFuturaUsd,
    showCnpjs,
    cnpjAtraso,
    cnpjAVencer,
    razaoSocial,
    boletosPagos,
    boletosEmAtraso,
    boletosAVencer,
    demo,
  } = props.data;
  return (
    <div className="mainChartDiv">
      {/* {this.valuesParcelas()} */}
      {razaoSocial && <CorporateName name={razaoSocial} filiais={showCnpjs} />}
      <TopValues
        colors={colors}
        values={{
          parcelas,
          totalPago,
          totalEmAtraso,
          totalAVencer,
          totalFuturaBrl,
          totalFuturaUsd,
          boletosPagos,
          boletosEmAtraso,
          boletosAVencer,
          demo
        }}
      />
      <CardGraphic
        listaAnos={listaAnos}
        parcelas={parcelas}
        cotacao={cotacao}
        colors={colors}
      />
      {showCnpjs && (
        <BottomValues
          demo={demo}
          colors={colors}
          cnpjAtraso={cnpjAtraso}
          cnpjAVencer={cnpjAVencer}
        />
      )}
    </div>
  );
};

export default AllowedUserView;
