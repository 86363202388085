import React from "react";
import Grid from "@material-ui/core/Grid";
import Grow from "@material-ui/core/Grow";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";

const WelcomeMessage = props => {
  return (
    <Grid item xs={12}>
      <Grow in={true}>
        <Card>
          <CardContent>
            <div className="topBody">
              <h2>Olá{" " + props.name}, como vai?</h2>
              <p align="justify" style={{ marginBottom: "0px" }}>
                A <b>SAFETEC</b> está sempre em busca de tornar sua experiência
                ainda melhor. Infome o CPF ou CNPJ no campo abaixo para visualizar seu
                histórico financeiro conosco.
              </p>
            </div>
          </CardContent>
        </Card>
      </Grow>
    </Grid>
  );
};

export default WelcomeMessage;
