import React from "react";

function CustomizedDot (props) {
  //Extra props: stroke, payload, value
  let { cx, cy, index, parcelas, colors } = props;
  let dotColor = "";
  if (parcelas[index].emAtraso) {
    dotColor = colors.colorEmAtraso;
  } else if (parcelas[index].aVencer) {
    dotColor = colors.colorAVencer;
  } else if (
    parcelas[index].futuraUsd ||
    parcelas[index].futuraBrl
  ) {
    dotColor = colors.colorFuturas;
  } else if (parcelas[index].pago) {
    dotColor = colors.colorPago;
  } else {
    dotColor = "rgba(0, 0, 0,0)";
  }
    return <circle cx={cx} cy={cy} r={7} fill={dotColor} />;
}

export default CustomizedDot;