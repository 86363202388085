import React, { Component } from "react";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { GoogleLogin } from "react-google-login";
import AllowedUserView from "../../components/AllowedUserView";
import FullLoading from "../../components/FullLoading";
import Login from "../../components/Login";
import NotAllowedUserView from "../../components/NotAllowedUserView";
import Search from "../../components/Search";
import SearchFilters from "../../components/SearchFilters";
import WelcomeMessage from "../../components/WelcomeMessage";
import CryptoJS from "crypto-js";
import api from "../../services/api";
import "./styles.css";

const key = process.env.REACT_APP_KEY;
toast.configure();

const colors = {
  colorPago: "#34A853",
  colorEmAtraso: "#EA4335",
  colorAVencer: "#FBBC05",
  colorFuturas: "#4285F4",
};
var name = localStorage.getItem("@painel-financeiro/n");
name = name
  ? CryptoJS.AES.decrypt(name, key).toString(CryptoJS.enc.Utf8)
  : name;
var email = localStorage.getItem("@painel-financeiro/e");
email = email
  ? CryptoJS.AES.decrypt(email, key).toString(CryptoJS.enc.Utf8)
  : email;

function isEmpty(obj) {
  for (var key in obj) {
    if (obj.hasOwnProperty(key)) return false;
  }
  return true;
}
export default class Dashboard extends Component {
  INITIAL_STATE = {
    name,
    email,
    listaAnos: [],
    cnpjAVencer: [],
    cnpjAtraso: [],
    fullLoading: false,
    chartLoading: false,
    disableSelect: true,
    disableButton: false,
    showData: false,
    filiais: false,
    showCnpjs: false,
    contratosAtivos: false,
    demo: false,
    ano: "",
    filters: {},
    colorTextPeriodo: "textSecondary",
    cnpj: "",
  };

  state = { ...this.INITIAL_STATE };

  regexCpfAndCnpj = /(^\d{3}.\d{3}.\d{3}-\d{2}$)|(^\d{2}.\d{3}.\d{3}\/\d{4}-\d{2}$)/;

  handleClickButton = (button) => async () => {
    let { ano, contratosAtivos, filiais, filters, cnpj } = this.state;

    if (button === "search") {
      if (!this.regexCpfAndCnpj.test(cnpj)) {
        this.errorMessage({
          type: "error",
          closeTimeInMs: 4000,
          position: "top-right",
        });
        return;
      }
      ano = new Date().getFullYear();
      await this.setState({
        fullLoading: true,
        disableButton: true,
        disableSelect: true,
        chartLoading: false,
        listaAnos: [],
        notFound: false,
        ano,
      });
    } else {
      ano =
        contratosAtivos && !filters.contratosAtivos
          ? new Date().getFullYear()
          : ano;
      await this.setState({
        disableButton: true,
        disableSelect: true,
        chartLoading: true,
        ano,
      });
    }
    await this.setState({
      filters: {
        ano,
        contratosAtivos,
        filiais,
      },
    });
    this.loadParcelas();
  };

  handleChange = (name, field) => async (event) => {
    await this.setState({ [name]: event.target[field] });
  };

  handleChangeInputCpfOrCnpj = (name, field) => async (event) => {
    await this.setState({ [name]: event.target[field] });
  };

  responseGoogleLogout = async () => {
    localStorage.clear();
    await this.setState({
      ...this.INITIAL_STATE,
      email: undefined,
      name: undefined,
      cnpj: "",
      authorized: false,
      notFound: false,
    });
  };

  responseGoogleLogin = (response) => {
    if (response && !response.error) {
      this.setState({
        name: response.profileObj.name,
        email: response.profileObj.email,
      });
      let encryptedName = CryptoJS.AES.encrypt(response.profileObj.name, key);
      localStorage.setItem("@painel-financeiro/n", encryptedName);
      let encryptedEmail = CryptoJS.AES.encrypt(response.profileObj.email, key);
      localStorage.setItem("@painel-financeiro/e", encryptedEmail);
    } else {
      this.setState({
        error: response.details,
        name: undefined,
        email: undefined,
      });
      localStorage.clear();
    }
  };

  loadParcelas = async () => {
    let { cnpj, ano, filiais, contratosAtivos, email, demo } = this.state;

    api
      .get("/parcelas", {
        params: {
          email,
          cnpj,
          ano,
          filiais,
          contratosAtivos,
        },
      })
      .then(async (res) => {
        await this.setState({
          ...res.data,
          razaoSocial: demo ? "EMPRESA EXEMPLO S/A" : res.data.razaoSocial,
          showCnpjs: filiais,
          fullLoading: false,
          chartLoading: false,
          disableSelect: false,
          disableButton: false,
        });

        if (res.data.notFound)
          this.errorMessage({
            type: "error",
            closeTimeInMs: 7000,
            position: "bottom-center",
          });
      });
  };

  errorMessage = ({ type, closeTimeInMs, position }) =>
    toast[type]("CNPJ inválido ou não foram encontradas parcelas.", {
      position: position,
      bodyClassName: "grow-font-size",
      autoClose: closeTimeInMs,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
    });

  body = () => {
    const { notFound, authorized, email } = this.state;

    if (notFound) {
      return;
    }

    return authorized ? (
      <AllowedUserView
        data={{
          ...this.state,
          colors,
        }}
      />
    ) : (
      <NotAllowedUserView
        data={{ email }}
        functions={{ responseGoogleLogout: this.responseGoogleLogout }}
      />
    );
  };

  render() {
    let {
      ano,
      cnpj,
      disableButton,
      disableSelect,
      filiais,
      contratosAtivos,
      demo,
      filters,
      listaAnos,
      chartLoading,
      fullLoading,
      name,
      email,
      authorized,
    } = this.state;
    return name && email ? (
      <Container maxWidth="md">
        <div style={{ display: "none" }}>
          <GoogleLogin
            className="loginButton"
            clientId="369967608635-ejfvu0mncqbpkj4nkecjt6lf73alinm8.apps.googleusercontent.com"
            onSuccess={this.responseGoogleLogin}
            onFailure={this.responseGoogleLogin}
            cookiePolicy={"single_host_origin"}
            buttonText="Login com Google"
            isSignedIn={true}
            autoLoad={false}
          />
        </div>
        <Grid container spacing={1} style={{ marginTop: 4 }}>
          <WelcomeMessage name={name} />
          <Search
            data={{ cnpj, disableButton, demo, email }}
            functions={{
              handleClickButton: this.handleClickButton,
              handleChangeInput: this.handleChangeInputCpfOrCnpj,
              handleChangeCheckbox: this.handleChange,
            }}
          />
          <SearchFilters
            data={{
              filiais,
              contratosAtivos,
              listaAnos,
              fullLoading,
              ano,
              disableSelect,
              disableButton,
              filters,
              chartLoading,
              authorized,
            }}
            functions={{
              handleClickButton: this.handleClickButton,
              handleChange: this.handleChange,
            }}
          />
        </Grid>

        <FullLoading loading={fullLoading} display="inherit" />
        {(!isEmpty(filters) && !fullLoading && this.body()) ||
          (listaAnos.length > 0 &&
            listaAnos.includes(ano) &&
            !fullLoading &&
            this.body())}
      </Container>
    ) : (
      <Container maxWidth="md">
        <Grid container spacing={1} style={{ marginTop: 4 }}>
          <Login
            responseGoogleLogin={this.responseGoogleLogin}
            isSignedIn={name && email}
          />
          <FullLoading loading={!name} display="flex" />
        </Grid>
      </Container>
    );
  }
}
