import React from "react";
import {numberWithCommas} from '../../utils';

function CustomizedLabel (props) {
  //Extra props: stroke
  const { x, y, value } = props;
  return (
    <text
      x={x}
      y={y}
      dy={-10}
      fill="#333333"
      fontSize={12.5}
      textAnchor="middle"
      fontWeight="bold"
    >
      {value !== 0 ? numberWithCommas(value) : ""}
    </text>
  );
}

export default CustomizedLabel;