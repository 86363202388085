import React from "react";
import { css } from "@emotion/core";
import { FadeLoader } from "react-spinners";

const FullLoading = props => {
  const { loading, display } = props;
  return (
    <div
      style={{
        marginTop: 20,
        marginBottom: 0,
        marginLeft: "auto",
        marginRight: "auto",
        display: loading ? display : "none"
      }}
    >
      <FadeLoader
        css={css`
          display: block;
          margin: 0 auto;
          border-color: red;
        `}
        height={15}
        width={5}
        radius={2}
        margin={2}
        color={"#f75a48"}
        loading={loading}
      />
    </div>
  );
};

export default FullLoading;
