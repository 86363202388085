import React from "react";
import { numberWithCommas } from "../../utils";

const CustomTooltip  = props => {
const { active, label, parcelas } = props;
  if (active) {
    var parcela = parcelas.filter(item => item.name === label)[0];
    return (
      <div
        style={{
          background: "rgba(255,255,255,0.8)",
          padding: 5
        }}
      >
        {parcela.pago > 0 && (
          <p className="label">{`Pago: R$ ${numberWithCommas(parcela.pago)}`}</p>
        )}
        {parcela.emAtraso > 0 && (
          <p className="label">{`Em Atraso: R$ ${numberWithCommas(
            parcela.emAtraso
          )}`}</p>
        )}
        {parcela.aVencer > 0 && (
          <p className="label">{`A Vencer: R$ ${numberWithCommas(
            parcela.aVencer
          )}`}</p>
        )}
        {parcela.futuraBrl > 0 && (
          <p className="label">{`Futura: R$ ${numberWithCommas(
            parcela.futuraBrl
          )}`}</p>
        )}
        {parcela.futuraUsd > 0 && (
          <p className="label">{`Futura: U$ ${numberWithCommas(
            parcela.futuraUsd
          )}`}</p>
        )}
      </div>
    );
  }

  return null;
};

export default CustomTooltip;